.post-card {
    position: relative;
    margin: 10px 0;
    padding: 40px 20px;
    text-align: left;
    transition: 0.1s;

    // divider
    &:not(:last-child) {
        border-bottom: 1px solid rgba(150, 150, 150, 0.3);
    }

    &__likes {
        position: absolute;
        top: 5px;
        right: 5px;
    }

    &__like-btn {
        vertical-align: text-top;

        &:hover {
            cursor: pointer;
        }
    }

    &__poster {
        font-style: italic;
    }

    &__title {
        margin: 5px 0;
        text-align: left;
        font-size: 1.4rem;
        font-weight: bold;
    }

    &__content {
        padding: 5px;
        border: 1px var(--color-text-primary) dashed;
    }
}
