.error-view {
    text-align: center;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        font-size: calc(10px + 2vmin);
        background: var(--color-bg-secondary);
        color: var(--color-text-primary);
    }
}
