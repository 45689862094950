@use "~scss/responsiveness";

.home-view {
    min-height: 100vh;
    text-align: center;
    background: var(--color-bg-secondary);

    &__header {
        padding: 20px 50px;
        font-size: 1.8rem;

        @include responsiveness.respond(phone) {
            font-size: 1.4rem;
        }

        @include responsiveness.respond(phone-narrow) {
            padding: 10px 8px;
            font-size: 1.2rem;
        }
    }

    &__container {
        margin: 0 auto;
        padding: 10px;
        max-width: 600px;

        @include responsiveness.respond(big-desktop) {
            max-width: 1000px;
        }
    }

    &__footer {
        margin-top: 30px;
        padding-bottom: 8px;
        color: #6c6c6c;

        &__heart {
            color: #ff6666;
        }

        a {
            color: #6c6c6c;
            text-decoration: none;

            &:hover {
                color: var(--color-text-primary);
            }
        }
    }
}
