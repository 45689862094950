.add-post-component {
    margin: 0;
    padding: 30px 0;
    background: var(--color-bg-primary);
    color: var(--color-text-primary);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    vertical-align: center;

    &__header {
        font-weight: bold;
        font-size: 1.4rem;
    }

    &__form {
        display: flex;
        flex-direction: column;
        margin: 0 20px;
    }

    &__status {
        text-align: left;
    }

    &__title {
        margin-bottom: 5px;
        padding: 2px 5px;
        width: 100%;
    }

    &__content {
        position: relative;
        margin-bottom: 5px;
        padding: 2px 5px;
        width: 100%;
        min-height: 50px;
        height: 100px;
        max-height: 150px;
        resize: vertical;

        &--fake {
            height: 50px;
            resize: none;
        }
    }

    &__group {
        width: 100%;
    }

    &__username {
        width: 60%;
    }

    &__submit {
        width: 40%;
    }
}
