@mixin respond($breakpoint) {
    @if ($breakpoint == smallest) {
        @media (max-width: 375px) {
            @content;
        }
    }

    @if ($breakpoint == phone-narrow) {
        @media (max-width: 35em) {
            @content;
        }
    }

    @if ($breakpoint == phone) {
        @media (max-width: 42.5em) {
            @content;
        }
    }

    @if ($breakpoint == tab-port) {
        @media (max-width: 56.25em) {
            @content;
        }
    }

    @if ($breakpoint == tab-land) {
        @media (max-width: 1024px) {
            @content;
        }
    }

    @if ($breakpoint == mission) {
        @media (max-width: 85.5em) {
            @content;
        }
    }

    @if ($breakpoint == desktop) {
        @media (max-width: 112.5em) {
            @content;
        }
    }

    @if ($breakpoint == big-desktop) {
        @media (min-width: 112.5em) {
            @content;
        }
    }
}
