$color-bg-primary: #fefefe;
$color-bg-secondary: #f4f4f4;
$color-text-primary: #112332;
$color-text-secondary: rgb(255, 147, 65);

:root {
    --color-bg-primary: #{$color-bg-primary};
    --color-bg-secondary: #{$color-bg-secondary};
    --color-text-secondary: #{$color-text-secondary};
    --color-text-primary: #{$color-text-primary};
}
