.posts-component {
    & > div {
        margin-bottom: 20px;
    }

    &__status {
        margin: 0;

        &--gray {
            color: #6c6c6c;
        }
    }
}
